class InputConfirmation {
  constructor($referenceInput, $confirmInput, $errorElement) {
    this.$referenceInput = $referenceInput;
    this.$confirmInput = $confirmInput;
    this.$errorElement = $errorElement;

    this.init();
  }

  init() {
    this.$errorElement.style.display = 'none';

    this.$referenceInput.addEventListener('input', () => this.validatePasswords());
    this.$confirmInput.addEventListener('input', () => this.validatePasswords());
  }

  validatePasswords() {
    const referenceValue = this.$referenceInput.value;
    const confirmValue = this.$confirmInput.value;

    if (referenceValue.length && confirmValue.length) {
      if (referenceValue === confirmValue) {
        this.$errorElement.style.display = 'none';
      } else {
        this.$errorElement.style.display = 'block';
      }
    }
  }
}

document.querySelectorAll('[data-js-confirm-reference-field]').forEach(($confirmField) => {
  const confirmFieldId = $confirmField.dataset.jsConfirmReferenceField;
  const $referenceField = document.querySelector(`[data-id=${confirmFieldId}]`);

  if ($referenceField) {
    const $referenceInput = $referenceField.querySelector('input');
    const $confirmInput = $confirmField.querySelector('input');
    const $errorElement = $confirmField.querySelector('.textinput__error');

    if ($referenceInput && $confirmInput && $errorElement) {
      return new InputConfirmation($referenceInput, $confirmInput, $errorElement);
    }
  }

  return null;
});
