import focusTrap from 'focus-trap';

class Header {
  constructor($el) {
    this.$el = $el;
    this.$container = $el.querySelector('.header__navigation-container');
    this.$menuOpen = $el.querySelector('.header__menu-open');
    this.$menuClose = $el.querySelector('.header__menu-close');
    this.$menuSearch = $el.querySelector('.header__menu-search');
    this.$menuItems = $el.querySelectorAll('.header__navigation-item');
    this.$menuMetaItems = $el.querySelectorAll('.header__meta-item:not(.header__meta-item--search)');
    this.$menuSearchItem = $el.querySelector('.header__meta-item--search');
    this.$searchButton = $el.querySelector('.header__meta-item--search .header__meta-link');
    this.$searchFlyout = $el.querySelector('.header__meta-item--search .header__flyout');
    this.$searchMenuBar = $el.querySelector('.header__menu-search-bar');
    this.$menuButtons = $el.querySelectorAll('.header__navigation-link');
    this.$menuMetaButtons = $el.querySelectorAll('.header__meta-link');

    this.focusTrap = focusTrap(this.$container, {
      initialFocus: this.$menuClose,
    });

    this.initMediaQuery({
      navigation: '(min-width: 1250px)',
    });

    this.SCROLL_OFFSET = 130;

    this.initAttributes();
    this.initEvents();
    this.initSticky();
  }

  initMediaQuery(constraints) {
    this.mediaQuery = {};

    Object.keys(constraints).forEach((key) => {
      this.mediaQuery[key] = window.matchMedia(constraints[key]);
    });
  }

  initAttributes() {
    [...this.$menuItems, ...this.$menuMetaItems].forEach(($item, index) => {
      const $flyout = $item.querySelector('.header__flyout');

      if ($flyout) {
        [
          ...$item.querySelectorAll('.header__navigation-link'),
          ...$item.querySelectorAll('.header__meta-link'),
        ].forEach(($button) => {
          $button.setAttribute('aria-expanded', 'false');
          $button.setAttribute('aria-controls', `menu-item-${index}`);
          $button.setAttribute('id', `menu-control-${index}`);

          $flyout.setAttribute('aria-labelledby', `menu-control-${index}`);
          $flyout.setAttribute('id', `menu-item-${index}`);
        });
      }
    });

    // Search
    this.$searchButton.setAttribute('aria-expanded', 'false');
    this.$searchButton.setAttribute('aria-controls', 'menu-item-search');
    this.$searchButton.setAttribute('id', 'menu-control-search');

    this.$searchFlyout.setAttribute('aria-labelledby', 'menu-control-search');
    this.$searchFlyout.setAttribute('id', 'menu-item-search');

    // Search (mobile)
    this.$menuSearch.setAttribute('aria-expanded', 'false');
    this.$menuSearch.setAttribute('aria-controls', 'menu-item-search-mobile');
    this.$menuSearch.setAttribute('id', 'menu-control-search-mobile');

    this.$searchMenuBar.setAttribute('aria-labelledby', 'menu-control-search-mobile');
    this.$searchMenuBar.setAttribute('id', 'menu-item-search-mobile');
  }

  initEvents() {
    this.$menuOpen.addEventListener('click', () => {
      this.toggleMenu();
      this.$menuClose.focus();
    });

    this.$menuClose.addEventListener('click', () => {
      this.toggleMenu();
      this.$menuOpen.focus();
    });

    [...this.$menuItems, ...this.$menuMetaItems].forEach(($item, index) => {
      const $flyout = $item.querySelector('.header__flyout');

      if ($flyout) {
        [
          ...$item.querySelectorAll('.header__navigation-link'),
          ...$item.querySelectorAll('.header__meta-link'),
        ].forEach(($button) => {
          $button.addEventListener('click', (event) => {
            if (!this.mediaQuery.navigation.matches) {
              event.preventDefault();
            }

            if (this.isExpandedMenuItem(index)) {
              this.collapseMenuItem(index);
            } else {
              this.expandMenuItem(index);
            }
          });

          $button.addEventListener('focus', () => {
            if (this.mediaQuery.navigation.matches) {
              this.expandMenuItem(index);
            }
          });

          $button.addEventListener('mouseover', () => {
            if (this.mediaQuery.navigation.matches) {
              this.$menuSearchItem.classList.remove('header__meta-item--expanded');
              this.$searchButton.setAttribute('aria-expanded', 'false');
              this.$menuSearchItem.querySelector('.search-bar__input').blur();

              this.expandMenuItem(index);
            }
          });

          $button.addEventListener('blur', () => {
            if (this.mediaQuery.navigation.matches) {
              if (this.isExpandedMenuItem(index)) {
                this.collapseMenuItem(index);
              }
            }
          });

          $button.addEventListener('mouseout', () => {
            if (this.mediaQuery.navigation.matches) {
              if (this.isExpandedMenuItem(index)) {
                this.collapseMenuItem(index);
              }
            }
          });
        });
      }
    });

    // Search
    this.$searchButton.addEventListener('mouseover', () => {
      this.$menuSearchItem.classList.add('header__meta-item--expanded');
      this.$searchButton.setAttribute('aria-expanded', 'true');
      this.$menuSearchItem.querySelector('.search-bar__input').focus();
    });

    this.$menuSearchItem.querySelector('.search-bar__input').addEventListener('blur', () => {
      this.$menuSearchItem.classList.remove('header__meta-item--expanded');
      this.$searchButton.setAttribute('aria-expanded', 'false');
    });

    // Search (mobile)
    this.$menuSearch.addEventListener('click', () => {
      this.$searchMenuBar.classList.add('header__menu-search-bar--expanded');
      this.$menuSearch.setAttribute('aria-expanded', 'true');
      this.$searchMenuBar.querySelector('.search-bar__input').focus();
    });

    document.addEventListener('click', (event) => {
      if (!this.$searchMenuBar.contains(event.target) && !this.$menuSearch.contains(event.target)) {
        this.$searchMenuBar.classList.remove('header__menu-search-bar--expanded');
        this.$menuSearch.setAttribute('aria-expanded', 'false');
      }
    });
  }

  initSticky() {
    window.addEventListener('scroll', () => {
      if ((document.documentElement.scrollTop || document.body.scrollTop) > this.SCROLL_OFFSET) {
        this.$el.classList.add('header--sticky');
      } else {
        this.$el.classList.remove('header--sticky');
      }
    });
  }

  toggleMenu() {
    document.documentElement.classList.toggle('has-overlay');

    this.$el.classList.toggle('header--active');

    if (this.$el.classList.contains('header--active')) {
      this.focusTrap.activate();
    } else {
      this.focusTrap.deactivate();
    }
  }

  expandMenuItem(index) {
    const $item = [...this.$menuItems, ...this.$menuMetaItems][index];

    $item.classList.add('header__navigation-item--expanded');

    $item.querySelectorAll('.header__navigation-link').forEach(
      $el => $el.setAttribute('aria-expanded', 'true'),
    );

    $item.querySelectorAll('.header__meta-link').forEach(
      $el => $el.setAttribute('aria-expanded', 'true'),
    );
  }

  collapseMenuItem(index) {
    const $item = [...this.$menuItems, ...this.$menuMetaItems][index];

    $item.classList.remove('header__navigation-item--expanded');

    $item.querySelectorAll('.header__navigation-link').forEach(
      $el => $el.setAttribute('aria-expanded', 'false'),
    );

    $item.querySelectorAll('.header__meta-link').forEach(
      $el => $el.setAttribute('aria-expanded', 'false'),
    );
  }

  isExpandedMenuItem(index) {
    const $item = [...this.$menuItems, ...this.$menuMetaItems][index];

    return ($item.querySelector('.header__navigation-link') || $item.querySelector('.header__meta-link'))
      .getAttribute('aria-expanded') === 'true';
  }
}

document.querySelectorAll('.js-header').forEach(
  $el => new Header($el),
);
