import h from 'hyperscript';
import { BREAKPOINTS } from 'javascripts/constants/index';
import generateIcon from 'components/_particles/icon/icon';
import Slider from 'javascripts/globals/slider';

class StageSlider extends Slider {
  initSlider() {
    this.slidesToShow = 1;
    this.mqls = [
      window.matchMedia(`(min-width: ${BREAKPOINTS.l})`),
      window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`),
    ];

    this.initClasses();

    this.$sliderItems.forEach(($slide, index) => {
      if (index === 0) {
        $slide.classList.add(`${this.options.sliderItemActive}`);
      }
    });

    if (this.options.dots === true && this.$sliderItems.length > 1) {
      this.initDots();
    }

    if (this.options.buttons === true && this.$sliderItems.length > 1) {
      this.initButtons();
    }

    this.autoplayInterval = null;
    this.autoplayDelay = 5000;
    this.startAutoplay();
  }

  onButtonClick(e) {
    const currScrollLeft = this.$sliderFrame.scrollLeft;
    const sliderItemWidth = this.$sliderItems[0].clientWidth;
    const totalSliderWidth = this.$sliderItems.length * sliderItemWidth;
    const visibleWidth = sliderItemWidth * this.slidesToShow;
    const sliderButtonNext = e.currentTarget.classList.contains('rsm-slider__button--next');

    let scrollAmount;

    if (sliderButtonNext) {
      // If the next scroll would go beyond the total width, reset to 0
      if (currScrollLeft + visibleWidth >= totalSliderWidth) {
        scrollAmount = 0;
      } else {
        scrollAmount = currScrollLeft + visibleWidth;
      }
      // If at the first slide and trying to scroll back, go to the last slide
    } else if (currScrollLeft === 0) {
      scrollAmount = totalSliderWidth - visibleWidth;
    } else {
      scrollAmount = currScrollLeft - visibleWidth;
    }

    this.$sliderFrame.scroll({
      left: scrollAmount,
      behavior: 'smooth',
    });
  }

  createButton(position) {
    const buttonClasses = [
      this.classes.sliderButton,
      `${this.classes.sliderButton}--${position}`,
      position === 'prev' && `${this.classes.sliderButton}--hidden`,
    ];

    // Button element
    const $button = h(`button.${buttonClasses.join('.')}`, {
      attrs: {
        type: 'button',
        'aria-hidden': this.options.tabbableSlides && true,
        tabindex: this.options.tabbableSlides ? -1 : 0,
      },
    }, [generateIcon({
      icon: position === 'prev' ? this.options.iconPrev : this.options.iconNext,
      classes: [`${this.classes.sliderButton}-icon`],
    })]);

    $button.addEventListener('click', this.onButtonClick.bind(this));

    return $button;
  }

  scrollToNextSlide() {
    const currScrollLeft = this.$sliderFrame.scrollLeft;
    const sliderItemWidth = this.$sliderItems[0].clientWidth;
    const totalSliderWidth = this.$sliderItems.length * sliderItemWidth;
    const visibleWidth = sliderItemWidth * this.slidesToShow;

    if (currScrollLeft + visibleWidth >= totalSliderWidth) {
      this.$sliderFrame.scroll({
        left: 0,
        behavior: 'smooth',
      });
    } else {
      this.$sliderFrame.scroll({
        left: currScrollLeft + visibleWidth,
        behavior: 'smooth',
      });
    }
  }

  startAutoplay() {
    if (this.autoplayInterval) return;

    this.autoplayInterval = setInterval(() => {
      this.scrollToNextSlide();
    }, this.autoplayDelay);
  }

  stopAutoplay() {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);

      this.autoplayInterval = null;
    }
  }

  createAutoplayButtons() {
    const $playButton = h(`button.${[this.classes.sliderButton].join('.')}`, {
      attrs: {
        type: 'button',
      },
      style: 'display: none;',
    }, [generateIcon({
      icon: 'play',
      classes: [`${this.classes.sliderButton}-icon`],
    })]);

    const $pauseButton = h(`button.${[this.classes.sliderButton].join('.')}`, {
      attrs: {
        type: 'button',
      },
    }, [generateIcon({
      icon: 'pause',
      classes: [`${this.classes.sliderButton}-icon`],
    })]);

    $playButton.addEventListener('click', () => {
      $playButton.style.display = 'none';
      $pauseButton.style.display = 'block';

      this.startAutoplay();
    });

    $pauseButton.addEventListener('click', () => {
      $pauseButton.style.display = 'none';
      $playButton.style.display = 'block';

      this.stopAutoplay();
    });

    return h('.stage-slider__autoplay-navigation', [
      $playButton, $pauseButton,
    ]);
  }

  initButtons() {
    const $buttonFrame = h(`div.${this.classes.sliderButtonFrame}`);
    $buttonFrame.append(this.createButton('prev'));
    $buttonFrame.append(this.createAutoplayButtons());
    $buttonFrame.append(this.createButton('next'));

    this.$sliderDotFrame.append($buttonFrame);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-stage-slider').forEach(
    $slideshow => new StageSlider($slideshow, {
      sliderFrame: 'stage-slider__items',
      sliderItem: 'stage-slider__item',
    }),
  );
});
