import h from 'hyperscript';
import randomId from 'javascripts/utils/random-id';
import generateIcon from 'components/_particles/icon/icon';

export default class Slider {
  constructor($el, options) {
    this.$el = $el;
    this.id = options.id || `slider${randomId()}`;

    this.options = {
      loop: this.$el.dataset.loop || true,
      buttons: this.$el.dataset.buttons || true,
      dots: this.$el.dataset.dots || true,
      tabbableSlides: true,
      controlText: this.$el.dataset.controlText || 'Go to slide',
      sliderFrame: 'rsm-slider__items',
      sliderItem: 'rsm-slider__item',
      sliderItemActive: 'rsm-slider__item-active',
      iconPrev: 'chevron-left',
      iconNext: 'chevron-right',
      ...options,
    };

    // Check for neccessary elements
    this.$sliderFrame = this.$el.querySelector(`.${this.options.sliderFrame}`);
    this.$sliderItems = this.$el.querySelectorAll(`.${this.options.sliderItem}`);

    if (this.$sliderFrame) {
      if (this.$sliderItems.length > 0) {
        this.initSlider();
      } else {
        // eslint-disable-next-line no-console
        console.error(`There are no items .${this.options.sliderItem}!`);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(`.${this.options.sliderFrame} does not exist.`);
    }
  }

  initClasses() {
    this.classes = {
      slider: 'rsm-slider',
      sliderFrame: 'rsm-slider__items',
      sliderItem: 'rsm-slider__item',
      sliderButtonFrame: 'rsm-slider__buttons',
      sliderButton: 'rsm-slider__button',
      sliderDotFrame: 'rsm-slider__dots',
      sliderDot: 'rsm-slider__dot',
    };

    // Append classes and IDs
    this.$el.classList.add(this.classes.slider);
    this.$sliderFrame.classList.add(this.classes.sliderFrame);
    this.$sliderItems.forEach(($slide, index) => {
      $slide.classList.add(this.classes.sliderItem);
      $slide.setAttribute('id', `${this.id}-slide${index}`);
    });
  }

  initSlider() {
    this.initClasses();

    this.$sliderItems.forEach(($slide, index) => {
      if (this.options.tabbableSlides) {
        $slide.setAttribute('tabindex', 0);
      }

      if (index === 0) {
        $slide.classList.add(`${this.options.sliderItemActive}`);
      }

      if (this.options.buttons === true && this.$sliderItems.length > 1) {
        this.initButtons($slide, index);
      }
    });

    if (this.options.dots === true && this.$sliderItems.length > 1) {
      this.initDots();
    }
  }

  onButtonClick(e) {
    const $button = e.currentTarget;
    e.preventDefault();

    const hash = $button.getAttribute('href');
    if (hash === null) return;

    const $target = document.getElementById(hash.substring(1));
    if ($target === null) return;

    this.$sliderItems.forEach(($slide) => {
      $slide.classList.remove(`${this.options.sliderItemActive}`);
    });
    $target.classList.add(`${this.options.sliderItemActive}`);

    this.$sliderFrame.scroll({
      left: $target.offsetLeft,
      behavior: 'smooth',
    });
  }

  createButton($slide, position) {
    // Calculate loopable index numbers
    const slideId = (idx, count) => idx - Math.floor(idx / count) * count;

    // Find slide IDs from neighbours
    const getButtonLinks = $slideElement => ({
      prev: $slideElement.previousElementSibling
        ? $slideElement.previousElementSibling.id
        : this.$sliderFrame.querySelector(`.${this.options.sliderItem}:last-child`).id,
      next: $slideElement.nextElementSibling
        ? $slideElement.nextElementSibling.id
        : this.$sliderFrame.querySelector(`.${this.options.sliderItem}:first-child`).id,
    });

    // Create link to neighbouring slide
    const buttonClasses = [
      this.classes.sliderButton,
      `${this.classes.sliderButton}--${position}`,
    ];

    const slideIndex = Array.from(this.$sliderItems).indexOf($slide);

    // Button element
    const $button = h(`a.${buttonClasses.join('.')}`, {
      href: `#${position === 'prev' ? getButtonLinks($slide).prev : getButtonLinks($slide).next}`,
      title: `${this.options.controlText} ${slideId(position === 'prev' ? slideIndex + 1 : slideIndex - 1, this.$sliderItems.length) + 1}`,
      attrs: {
        'aria-hidden': this.options.tabbableSlides && true,
        tabindex: this.options.tabbableSlides ? -1 : 0,
      },
    }, [generateIcon({
      icon: position === 'prev' ? this.options.iconPrev : this.options.iconNext,
      classes: [`${this.classes.sliderButton}-icon`],
    })]);

    $button.addEventListener('click', this.onButtonClick.bind(this));

    return $button;
  }

  initButtons($slide, slideIndex) {
    this.$buttonFrame = h(`div.${this.classes.sliderButtonFrame}`);

    // Append specific button elements
    if ((this.options.loop === true) || slideIndex > 0) {
      this.$buttonFrame.append(this.createButton($slide, 'prev'));
    }
    if ((this.options.loop === true) || (slideIndex + 1) < this.$sliderItems.length) {
      this.$buttonFrame.append(this.createButton($slide, 'next'));
    }

    $slide.append(this.$buttonFrame);
  }

  initDots() {
    // Create dot list
    this.$sliderDotList = h(`ul.${this.classes.sliderDotFrame}-list`);

    // Fill list with links
    this.$sliderItems.forEach(($slide, slideIndex) => {
      const $sliderDot = h(`li.${this.classes.sliderDotFrame}-item`,
        h(`a.${this.classes.sliderDot}`, {
          attrs: {
            href: `#${$slide.id}`,
            'aria-current': (slideIndex === 0) && 'true',
          },
        },
        h('span.u-hidden-visually', `${this.options.controlText} ${slideIndex + 1}`)));

      $sliderDot.querySelector(`.${this.classes.sliderDot}`).addEventListener('click', this.onButtonClick.bind(this));
      this.$sliderDotList.append($sliderDot);
    });

    this.$sliderFrame.addEventListener('scroll', () => {
      // Get index number of the most visible slide
      const visibleSlideIndex = Math.round(
        this.$sliderFrame.scrollLeft / this.$sliderFrame.clientWidth,
      );

      // Mark corresponding slider dot if slide is visible
      this.switchDot(
        this.$sliderDotList.childNodes[visibleSlideIndex].lastElementChild,
      );
    }, { passive: true });

    // Create dot wrapper
    this.$sliderDotFrame = h(`nav.${this.classes.sliderDotFrame}`, this.$sliderDotList);

    // Append dot frame
    this.$el.append(this.$sliderDotFrame);
  }

  switchDot($sliderDot) {
    this.$sliderDotList.querySelectorAll(`.${this.classes.sliderDot}`)
      .forEach(($dot) => {
        $dot.removeAttribute('aria-current');

        if ($dot === $sliderDot) {
          $dot.setAttribute('aria-current', 'true');
        }
      });
  }
}
