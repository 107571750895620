export const ICON_SPRITE_URL = window.kesselblech.ICON_SPRITE_URL || '/icons/icons.svg';
export const LANG = document.documentElement.lang || 'de';
export const BREAKPOINTS = {
  s: '0px',
  m: '480px',
  l: '768px',
  xl: '990px',
  xxl: '1440px',
  navigation: '1250px',
};
